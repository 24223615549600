<template>
  <button
      type="button"
      class="btn"
      :class="[{btn__big: isBig}, `btn__${colorType}`]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "bc-button",
  props: {
    isBig: {
      type: Boolean,
      default() {
        return false
      }
    },
    colorType: {
      /**
       * ['white', ] : default 'black'
       */
      type: String,
      default() {
        return 'black'
      }
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
//:root {
//  --btn-padding-tb: 15px;
//  --btn-padding-lr: 34px;
//  --btn-padding-big: 24px 44px;
//}
//@media (max-width: 600px) {
//  --btn-padding: 15px 34px;
//  --btn-padding-big: 24px 44px;
//}

.btn {
  border: 1px solid #161616;
  background: $black;
  color: $white;

  font-family: unset;
  cursor: pointer;
  text-decoration: none;
  transition: 250ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: inherit;
  padding: .8em 1.7em;
  user-select: none;

  &__big {
    padding: 1em 2em;
    //font-size: 20px;
  }

  &:hover,
  &:focus {
    opacity: .7;
  }
  &:active {
     transform: scaleX(0.98);
   }

  &__white {
    background: $white;
    color: $black;
  }
}
@media screen and (max-width: 600px) {
  .btn {
    padding: .7em 1.2em;
  }
}

</style>
<template>
  <div class="bc-accordion-big"
       :class="{active: isActive, invert: isWhiteScheme}">
    <div class="bc-accordion-big__title"
         @click="$emit('setActiveBigAccordion')">
      {{ title }}
      <img class="bc-accordion-big__title__toggle"
           :class="{active: isActive, invert: isWhiteScheme}"
           src="@/assets/img/icons/more.svg" alt="more">
    </div>
    <div class="bc-accordion-big__content"
         :class="{active: isActive}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "bc-accordion-big",
  props: {
    title: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default() {
        return false
      }
    },
    isWhiteScheme: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bc-accordion-big {
  padding: 30px 0;
  border: 1px $black solid;
  overflow: hidden;
  transition: .9s;

  &.active {
    padding-bottom: 0;
  }
  &.invert {
    border-color: $white;
  }

  &__title {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4em;
    cursor: pointer;

    &__toggle {
      height: 30px;
      width: 30px;
      transition: .3s;

      &.active {
        transform: rotate(45deg);
      }
      &.invert {
        filter: invert(1);
      }
    }
  }
  &__content {
    transition: .5s;
    max-height: 0;
    opacity: 0;

    &.active {
      max-height: 8000px; // can't be that long
      opacity: 1;
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 600px) {
  .bc-accordion-big {
    &__title {
      padding: 0 15px;
    }
  }
}
</style>

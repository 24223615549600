<template>
  <transition name="fade" mode="out-in">
    <div v-if="isActive"
         class="bc-thanks-for-order">
      <div class="container">
        <div class="container__title">
          {{ $t('DIALOGS.THANKS_FOR_ORDER.TITLE') }}
        </div>
        <div class="container__subtitle">
          {{ $t('DIALOGS.THANKS_FOR_ORDER.SUBTITLE') }}
        </div>
        <div class="container__logo">
          <img src="@/assets/img/logo.svg" alt="bc-logo">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "bc-thanks-for-order",
  props: {
    isActive: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bc-thanks-for-order {
  color: $black;
  background: $white;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 3100;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 1.85em;
      margin-bottom: 30px;
    }

    &__subtitle {
      font-size: .9em;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
</style>
<template>
  <div class="loader">
    <svg class="circular">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: "bc-progress-circular",

}
</script>

<style scoped lang="scss">
$width: 50px;

.loader{
  width: $width;
  height: $width;
  //transform: translate(-50%,-50%);
}

.circular{
  animation: rotate 2s linear infinite;
  height: $width;
  position: relative;
  width: $width;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke: $white;
  animation:
      dash 1.5s ease-in-out infinite,
      color 6s ease-in-out infinite
;
  stroke-linecap: round;
}

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}
@keyframes dash{
  0%{
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
</style>
import {createStore} from "vuex";

function updateLocalStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart))
}

export const store = createStore({
    state: {
        cart: [],
        isActiveOrderPopup: false,
        isOpenMainMenu: false,
        appLanguage: localStorage.getItem("VueAppLanguage") || 'uk',
        seoData: []
    },
    getters: {
        productQuantity: state => product => {
            const item = state.cart.find(i => i.id === product.id)

            if (item) return item.quantity
            else return null
        },
        cartItems: state => {
            return state.cart
        },
        cartTotal: state => {
            return state.cart.reduce((a, b) => a + (b.price * b.quantity), 0)
        },
        cartItemsCount: state => {
            return state.cart.length
        },
        //-------
        getIsActiveOrderPopup: state => {
           return state.isActiveOrderPopup
        },
        getIsOpenMainMenu: state => {
           return state.isOpenMainMenu
        },
        getAppLanguage: (state) => state.appLanguage,
        getSeoDataForPage: state => pageName => {
            const seoData = state.seoData.find(i => i.page === pageName)
            if (seoData) return seoData
            else return null
        }
    },
    mutations: {
        addToCart(state, { product, amount }) {
            let item = state.cart.find(i => i.id === product.id && i.preferred_option?.id === product.preferred_option?.id)
            // console.log(item)

            if (item) {
                item.quantity += amount
            } else {
                state.cart.push({...product, quantity: amount})
            }

            updateLocalStorage(state.cart)
        },
        removeFromCart(state, product) {
            let item = state.cart.find(i => i.id === product.id && i.preferred_option?.id === product.preferred_option?.id)
            if (item) {
                // console.log(item)
                state.cart = state.cart.filter(i => i.id !== product.id || i.preferred_option?.id !== product.preferred_option?.id)
            }
            updateLocalStorage(state.cart)
        },
        decrementFromCart(state, product) {
            let item = state.cart.find(i => i.id === product.id && i.preferred_option?.id === product.preferred_option?.id)
            if (item) {
                if (item.quantity > 1) {
                    item.quantity--
                } else {
                    store.commit('removeFromCart', product)
                }
            }
            updateLocalStorage(state.cart)
        },
        updateCartFromLocalStorage(state) {
            const cart = localStorage.getItem('cart')
            if (cart) {
                state.cart = JSON.parse(cart)
            }
        },
        clearCart(state) {
            state.cart = []
            updateLocalStorage(state.cart)
        },
        //---------
        setIsActiveOrderPopup(state, value) {
            state.isActiveOrderPopup = value
        },
        setIsOpenMainMenu(state, value) {
            state.isOpenMainMenu = value
        },
        setAppLanguage(state, language) {
            state.appLanguage = language
            localStorage.setItem("VueAppLanguage", language)
        },
        setSeoData(state, seoData) {
            state.seoData = seoData
        }
    },
    actions:{},
    modules:{}
})

<template>
  <router-link :to="localeTo">
    <slot/>
  </router-link>
</template>

<script>
import {getLocaleLink} from "@/utils";

export default {
  name: "bc-router-link",
  props: ['to'],
  computed: {
    localeTo() {
      return getLocaleLink(this.to)
    }
  },
}
</script>

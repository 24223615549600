<template>
  <div class="bc-footer">
    <div class="component">
      <div class="bc-footer__content">
        <div class="bc-footer__content__logo">
          <bc-router-link :to="'/'">
            <img src="@/assets/img/logo-white.svg" alt="bc-logo">
          </bc-router-link>

          <div class="bc-footer__content__logo__txt">
            <div>Beauty Code</div>
            <div>{{ $t('FOOTER.MOTTO') }}</div>
          </div>
        </div>
        <div class="bc-footer__content__offers">
          <div class="bc-footer__title">
            {{ $t('FOOTER.MENU.SUGGESTIONS.TITLE') }}
          </div>
          <div>
            <bc-router-link :to="'/services'">
              {{ $t('FOOTER.MENU.SUGGESTIONS.SERVICES') }}
            </bc-router-link>
          </div>
          <div v-if="false">
            <bc-router-link :to="'/shop'">
              {{ $t('FOOTER.MENU.SUGGESTIONS.SHOP') }}
            </bc-router-link>
          </div>
        </div>
        <div class="bc-footer__content__useful">
          <div class="bc-footer__title">
            {{ $t('FOOTER.MENU.USEFUL.TITLE') }}
          </div>
          <div>
            <bc-router-link :to="'/works'">
              {{ $t('FOOTER.MENU.USEFUL.WORKS') }}
            </bc-router-link>
          </div>

          <div>
            <bc-router-link :to="'/specialists'">
              {{ $t('FOOTER.MENU.USEFUL.ABOUT') }}
            </bc-router-link>
          </div>
          <div>
            <bc-router-link :to="'/discounts'">
              {{ $t('FOOTER.MENU.USEFUL.DISCOUNTS') }}
            </bc-router-link>
          </div>
          <div>
            <bc-router-link :to="`/#${homeSocialsId}`">
              {{ $t('FOOTER.MENU.USEFUL.COMMUNITY') }}
            </bc-router-link>
          </div>
        </div>
        <div class="bc-footer__content__contacts">
          <div class="bc-footer__title">{{ $t('FOOTER.MENU.CONTACTS.TITLE') }}</div>
          <div>
            <a href="tel:+380955576600">095 557 66 00</a>
          </div>
          <div>
            <a href="tel:+380975576600">097 557 66 00</a>
          </div>
          <div>{{ $t('FOOTER.MENU.CONTACTS.ADDRESS') }}</div>
          <div>{{ $t('FOOTER.MENU.CONTACTS.PARKING') }}</div>
        </div>
        <div class="bc-footer__content__socials">
          <div class="bc-footer__title">{{ $t('FOOTER.MENU.SOCIALS') }}</div>
          <div class="bc-footer__content__socials__icons">
            <a href="https://www.facebook.com/beautycodeua/" target="_blank">
              <img src="@/assets/img/icons/fb.svg" alt="bc-facebook">
            </a>
            <a href="https://www.instagram.com/beautycode.ua/" target="_blank">
              <img src="@/assets/img/icons/insta-white.svg" alt="bc-instagram">
            </a>
            <a href="https://www.youtube.com/channel/UCksFq0nOnFzDvCMg4AtcZ3Q" target="_blank">
              <img src="@/assets/img/icons/youtube.svg" alt="bc-youtube">
            </a>
            <a href="https://t.me/BeautyCodeAG" target="_blank">
              <img src="@/assets/img/icons/tg.svg" alt="bc-telegram">
            </a>
          </div>
        </div>
      </div>
      <div class="bc-footer__rights">
        Beauty-code © {{ new Date().getFullYear() }} {{ $t('FOOTER.RIGHTS') }}
      </div>
    </div>
  </div>
</template>

<script>

import homeSocials from "@/components/home/home-socials";

export default {
  name: "bc-footer",
  data() {
    return {
      homeSocialsId: homeSocials.name
    }
  }
}
</script>

<style scoped lang="scss">
.bc-footer {
  background: $black;
  color: $white;
  font-size: 0.675em;
  position: relative;

  &__title {
    color: $dark-gray;
    margin-bottom: 1.25em;
  }

  &__content {
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr) 1.5fr 1fr;
    justify-items: start;

    &__logo {
      a {
        width: 136px;
      }

      &__txt {
        margin-top: 2em;
      }
    }

    > div > div {
      margin-bottom: 1.25em;
    }

    &__offers > div:not(:first-child),
    &__useful > div:not(:first-child) {
      cursor: pointer;
    }

    &__socials {
      &__icons {
        display: flex;

        > a {
          margin-right: 20px;
        }
      }
    }
  }

  &__rights {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    left: 0;
    right: 0;
    font-size: .85em;
  }
}

@media screen and (max-width: 800px) {
  .bc-footer {
    height: auto;

    &__content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);

      &__logo {
        grid-area: 1 / 1 / 2 / 2;
      }

      &__offers {
        grid-area: 2 / 1 / 3 / 2;
      }

      &__useful {
        grid-area: 2 / 2 / 3 / 3;
      }

      &__contacts {
        grid-area: 2 / 3 / 3 / 4;
      }

      &__socials {
        grid-area: 3 / 1 / 4 / 2;
      }

    }
  }
}


@media screen and (max-width: 600px) {
  .bc-footer {
    &__content {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr) 1.5fr;

      &__contacts {
        grid-area: 3 / 1 / 4 / 2;
      }

      &__socials {
        grid-area: 4 / 1 / 5 / 2;
      }

    }
  }
}
</style>

<template>
  <div class="home-services">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('HOME.SERVICES.TITLE') }}
      </div>
      <div class="home-services__content">
        <div class="home-services__content__description">
          <div class="component__subtitle"
               v-html="$t('HOME.SERVICES.SUBTITLE')"></div>
          <div class="images">
            <img src="@/assets/img/services/1.png" alt="service-photo">
            <img src="@/assets/img/services/2.png" alt="service-photo">
          </div>
        </div>
        <div class="home-services__content__prices">
          <bc-accordion-big class="category"
                            v-for="category in services || []" :key="category.id"
                            @set-active-big-accordion="setActiveCategory(category.id)"
                            :is-active="activeCategory === category.id"
                            :is-white-scheme="true"
                            :title="category.title">
            <bc-accordion class="category__subcategory"
                          v-for="subcategory in category.subcategories" :key="subcategory.id"
                          @set-active-accordion="setActiveSubCategory(subcategory.id)"
                          :is-active="activeSubCategory === subcategory.id"
                          :is-white-scheme="true"
                          :title="subcategory.title">
              <div class="category__subcategory__service"
                   v-for="service in subcategory.services" :key="service.id">
                <div class="category__subcategory__service__title">{{ service.title }}</div>
                <div class="category__subcategory__service__price">
                  {{ service.price ? service.price : $t('SERVICES.FREE') }}<span
                    v-if="service.price">&nbsp;{{ $t('INTERFACE.CURRENCY') }}</span>
                </div>
              </div>
            </bc-accordion>

          </bc-accordion-big>
        </div>
      </div>

      <div class="home-services__more">
        <bc-router-link :to="'/services'">
          <bc-button
              :color-type="'white'">
            {{ $t('HOME.SERVICES.BTN') }}
          </bc-button>
        </bc-router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-services",
  props: ['services'],
  components: {},
  mounted() {
    this.activeCategory = this.services[0].id
    this.activeSubCategory = this.services[0].subcategories[0].id
  },
  methods: {
    setActiveCategory(categoryId) {
      if (this.activeCategory === categoryId) {
        this.activeCategory = null
      } else {
        this.activeCategory = categoryId
      }
      this.activeSubCategory = null
    },
    setActiveSubCategory(subcategoryId) {
      if (this.activeSubCategory === subcategoryId) {
        this.activeSubCategory = null
      } else {
        this.activeSubCategory = subcategoryId
      }
    },
  },
  data() {
    return {
      error: null,
      activeCategory: null,
      activeSubCategory: null
    }
  }
}
</script>

<style scoped lang="scss">
.home-services {
  background: $black;
  color: $white;

  &__content {
    display: flex;
    justify-content: space-between;

    &__description,
    &__prices {
      width: 48%;
    }

    &__description {

      .images {
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 80px;

        > img {
          width: 45%;

          &:last-child {
            margin-top: 150px;
          }
        }
      }
    }

    &__prices {

      .category {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &__subcategory {
          position: relative;
          padding: 20px 0;

          &:not(:last-child):after {
            position: absolute;
            content: '';
            border-bottom: 1px solid #ffffff;
            width: 92%;
            transform: translateX(-50%);
            bottom: 0;
            left: 50%;
          }

          &__service {
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: .4s;
            padding: 13px 30px;

            &:hover {
              background-color: $white;
              color: $black;
            }

            &__price {
              margin-left: 20px;
            }


          }
        }
      }
    }
  }

  &__more {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .home-services {
    &__content {
      flex-direction: column;

      &__description,
      &__prices {
        width: 100%;
      }

      &__description {
        .component__subtitle {
          text-align: center;
          margin-bottom: 40px;
        }

        .images {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-services {
    &__content {
      &__prices {
        .category {
          &__subcategory {
            &__service {
              padding: 13px 15px;
            }
          }
        }
      }
    }
  }
}
</style>
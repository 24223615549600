<template>
  <div class="home-main">
    <div class="component">
      <div class="home-main__left">
        <div class="home-main__left__title">
          {{ data?.title || $t('HOME.MOTTO')}}
        </div>
        <div class="home-main__left__text">
          {{ data?.subtitle || $t('HOME.DESCRIPTION')}}
        </div>
        <bc-button
            :is-big="true"
            @click="$router.push(`#${homeOrderId}`)"
            v-html="$t('INTERFACE.BOOKING')">
        </bc-button>
      </div>
      <img class="home-main__gif"
           :src="data?.gif || require('@/assets/img/home/main.gif')"
           alt="bc-main">
    </div>
  </div>
</template>

<script>
import HomeOrder from "@/components/home/home-order";

export default {
  name: 'home-main',
  props: ['data'],
  components: {},
  methods: {},
  data() {
    return {
      homeOrderId: HomeOrder.name,
      title: null,
      subtitle: null,
      gif: null
    }
  }
}
</script>

<style scoped lang="scss">
.home-main {
  height: auto;
  margin-top: var(--header-height);

  .component {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    margin: auto 0;
    padding-bottom: 20px;
    max-width: 765px;
    padding-right: 20px;

    &__title {
      font-size: 3em;
      max-width: 620px;
      line-height: 120%;
    }

    &__text {
      line-height: 170%;
      margin-top: 30px;
      margin-bottom: 2em;
      padding-right: 30px;
    }
  }

  &__gif {
    height: 765px;
    width: 775px;
    filter: brightness(0.7);
    margin-top: 30px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1600px) {
  .home-main {
    &__left {
      &__title {
        font-size: 3em;
      }
    }

    &__gif {
      max-width: 45%;
      height: 600px;
    }
  }
}

@media screen and (max-width: 960px) {
  .home-main {
    .component {
      flex-direction: column;
      align-items: center;
    }

    &__left {
      margin-top: 40px;
      text-align: center;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        max-width: inherit;
        font-size: 2.5em;
      }

      &__text {
        padding-right: 0;
      }
    }

    &__gif {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>

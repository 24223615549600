<template>
  <div class="home-order" :id="this.$options.name">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('ORDER_FORM.TITLE') }}
      </div>
      <div class="home-order__content">
        <form class="home-order__content__form" ref="form">

          <div>
            <input
                :class="{ error: v$.form.name.$errors.length }"
                type="text"
                :placeholder="$t('ORDER_FORM.NAME')"
                v-model.lazy="v$.form.name.$model">
            <Transition name="slide-fadeUp">
              <div
                  class="input-error"
                  v-if="v$.form.name.$errors.length">
                {{ v$.form.name.$errors[0].$message }}
              </div>
            </Transition>
          </div>

          <div>
            <input
                :class="{ error: v$.form.phone.$errors.length }"
                type="text"
                :placeholder="$t('ORDER_FORM.PHONE')"
                v-model.lazy="v$.form.phone.$model">
            <Transition name="slide-fadeUp">
              <div
                  class="input-error"
                  v-if="v$.form.phone.$errors.length">
                {{ v$.form.phone.$errors[0].$message }}
              </div>
            </Transition>
          </div>

          <div>
            <textarea
                :placeholder="$t('ORDER_FORM.COMMENT')"
                rows="3"
                v-model.lazy="form.message">
            </textarea>
          </div>

          <div class="home-order__content__form__actions">
            <bc-progress-circular v-if="isSentForm"/>
            <bc-button v-html="$t('INTERFACE.BOOKING')"
                :color-type="'white'"
                :is-big="true"
                @click="sendForm">
            </bc-button>
            <bc-thanks-for-order :isActive="isSentForm"></bc-thanks-for-order>
          </div>


        </form>
      </div>

      <div class="home-order__map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.5362275339053!2d30.52646614889141!3d50.44256235602365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce551079cd1d%3A0x52bcb124bb15afc8!2z0KHQsNC70L7QvSDQutGA0LDRgdC-0YLRiyBCZWF1dHljb2Rl!5e0!3m2!1sru!2sua!4v1651076533168!5m2!1sru!2sua"
            height="100%" width="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>

  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import {phoneValidation, requiredValidation} from "@/validators/validators";

export default {
  name: "home-order",
  components: {},

  setup() {
    return {v$: useVuelidate()}
  },
  methods: {
    async sendForm() {
      // eslint-disable-next-line no-undef
      fbq('track', 'InitiateCheckout')

      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      this.$http.post('/api/order/procedure/', this.form).then(() => {
        // this.instaList = resp.data
        this.isSentForm = true
        setTimeout(() => {
          this.isSentForm = false
        }, 3000)

        this.v$.$reset()
        this.form.name = ''
        this.form.phone = ''
        this.form.message = ''
        // console.log(resp)
      }).catch(err => {
        // this.error = true
        console.error(err)
      })
      // console.log(this.form)

      this.isSentForm = false
    },
  },

  data() {
    return {
      form: {
        name: '',
        phone: '',
        message: ''
      },
      isSentForm: false
    }
  },
  validations() {
    return {
      form: {
        name: {
          required_validation: {
            $validator: requiredValidation,
            $message: this.$i18n.t('ORDER_FORM.REQUIRED_ERROR')
          }
        },
        phone: {
          required_validation: {
            $validator: requiredValidation,
            $message: this.$i18n.t('ORDER_FORM.REQUIRED_ERROR')
          },
          phone_validation: {
            $validator: phoneValidation,
            $message: this.$i18n.t('ORDER_FORM.NOT_VALID_PHONE_ERROR')
          }
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-order {
  background: $black;
  color: $white;
  overflow: hidden;

  .component {
    position: relative;

    &__title {
      text-align: start;
      max-width: 50%;
    }
  }

  &__content {
    &__form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 650px;

      > div {
        position: relative;
        margin-bottom: 1.5em;
        width: 100%;
      }

      &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > button {
          margin-left: 15px;
        }
      }
    }
  }

  &__map {
    position: absolute;
    width: 924px;
    height: 750px;
    top: 0;
    right: -200px;
  }
}

@media screen and (max-width: 1600px) {
  .home-order {
    &__content {
      &__form {
        max-width: 45%;
      }
    }

    &__map {
      width: 50%;
      height: 600px;
      right: 0;
    }
  }
}

@media screen and (max-width: 860px) {
  .home-order {
    &__map {
      height: 500px;
    }
  }
}

@media screen and (max-width: 780px) {
  .home-order {
    .component {
      &__title {
        text-align: center;
        max-width: 100%;
      }
    }

    &__content {
      &__form {
        max-width: 100%;
      }
    }

    &__map {
      position: static;
      height: 400px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-order__content__form__actions {
    flex-direction: column;
    align-items: stretch;

    & > button {
      margin-left: 0;
    }
  }
}
</style>

<template>
  <transition name="fade">
    <div
        v-if="!cookieAgreed"
        class="bc-cookies component">
      <div class="bc-cookies__txt">
        <div>{{ $t('COOKIES.TITLE') }}</div>
        <div>{{ $t('COOKIES.SUBTITLE') }}</div>
      </div>
      <div class="bc-cookies__btn">
        <bc-button
            :color-type="'white'"
            @click="cookieAgreed = true"
        >
          {{ $t('COOKIES.BUTTON') }}
        </bc-button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "bc-cookies",
  data() {
    return {
      cookieAgreed: false
    }
  },
  created() {
    if (localStorage.cookie) {
      this.cookieAgreed = true
    }
  },
  watch: {
    cookieAgreed(newValue) {
      if (newValue) {
        localStorage.cookie = 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bc-cookies {
  height: auto;
  position: fixed;
  bottom: 0;
  z-index: 30;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.77);
  backdrop-filter: blur(10px);
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  &.component {
    max-width: 100%;
  }

  &__txt {
    color: $white;
    text-align: start;
    padding-right: 10px;

    div:last-child {
      font-size: .8em;
      opacity: 0.8;
    }
  }
}

@media (max-width: 700px) {
  .bc-cookies {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    &__btn {
      margin-top: 10px;

    }
  }
}

</style>
import {createWebHistory, createRouter} from 'vue-router'

import {setGlobalLocale} from "@/utils";
import {store} from "@/store";

import bcHome from "@/pages/bc-home";

const mainRoutes = [
    {path: '', component: bcHome},
    {path: 'services', component: () => import(/* webpackChunkName: "bc-services" */ '../pages/bc-services')},
    {path: 'works', component: () => import(/* webpackChunkName: "bc-works" */ '../pages/bc-works')},
    {path: 'specialists', component: () =>  import(/* webpackChunkName: "bc-specialists" */ '../pages/bc-specialists')},
    {path: 'discounts', component: () => import(/* webpackChunkName: "bc-discounts" */ '../pages/bc-discounts')},
    {
      path: 'shop', 
      component: () => import(/* webpackChunkName: "bc-shop" */ '../pages/bc-shop'), 
      redirect: {
        name: '' 
      }
    },
    {
      path: 'shop/:productSlug', 
      component: () => import(/* webpackChunkName: "bc-shop-product" */ '../pages/bc-shop-product'), 
      redirect: {
        name: ''
      }
    },
    {path: 'cart', component: () => import(/* webpackChunkName: "bc-cart" */ '../pages/bc-cart')},
    {path: '500', component: () => import(/* webpackChunkName: "bc-500" */ '../pages/bc-500')},
]

const routes = [
    {path: '/', component: {template: '<router-view/>'}, children: mainRoutes, strict: false},
    {path: '/en', component: {template: '<router-view/>'}, children: mainRoutes, strict: false},
    {path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "bc-404" */ '../pages/bc-404')}
]

function wait(duration) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
    while (timeout > 0) {
        const el = document.querySelector(hash);
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
            break;
        }
        await wait(delay);
        timeout = timeout - delay;
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // Required because our <RouterView> is wrapped in a <Transition>
            // So elements are mounted after a delay
            tryScrollToAnchor(to.hash, 1000, 100);
        } else if (savedPosition) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({...savedPosition, behavior: 'smooth'})
                }, 1500)
            })
        } else {
            return { top: 0 };
        }
    },
})

router.beforeEach((to, from, next) => {

    if (store.getters.getAppLanguage === 'en' && !to.path.includes('en')) {
        // eng lang but path without prefix 'en'
        return next({path: `/en${to.path}`.replace(/\/$/, "")})
    }

    if (to.path.slice(1,3) === 'en' && store.getters.getAppLanguage !== 'en') {
        // path with prefix 'en' but lang is not eng
        setGlobalLocale('en')
    }
    next()
})

export default router


// import translations from '../i18n/index';
// import bcSpecialists from "@/pages/bc-specialists";
// import {store} from "@/store";


// const langRouterOptions = {
//     defaultLanguage: store.getters.getAppLanguage || 'uk',
//     translations,
// };

// function wait(duration) {
//     return new Promise((resolve) => setTimeout(resolve, duration));
// }
//
// async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
//     while (timeout > 0) {
//         const el = document.querySelector(hash);
//         if (el) {
//             el.scrollIntoView({ behavior: "smooth" });
//             break;
//         }
//         await wait(delay);
//         timeout = timeout - delay;
//     }
// }
//
// const routerOptions = {
//     routes,
//     history: createWebHistory(),
//     scrollBehavior(to, from, savedPosition) {
//         if (to.hash) {
//             // Required because our <RouterView> is wrapped in a <Transition>
//             // So elements are mounted after a delay
//             tryScrollToAnchor(to.hash, 1000, 100);
//         } else if (savedPosition) {
//             return new Promise((resolve) => {
//                         setTimeout(() => {
//                             resolve({...savedPosition, behavior: 'smooth'})
//                         }, 1500)
//                     })
//         } else {
//             return { top: 0};
//         }
//     },
// };

// const router = createLangRouter(langRouterOptions, routerOptions);

// export default router

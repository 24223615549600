<template>
  <div class="home-socials" :id="this.$options.name">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('HOME.SOCIALS.TITLE') }}
      </div>
      <div class="home-socials__swiper" v-if="instaList.length">
        <swiper
            :modules="modules"
            :slides-per-view="3"
            :breakpoints="swiperOptions.breakpoints"
            :initial-slide="1"
            :loop="true"
            :looped-slides="50"
            :css-mode="false"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="home-socials__swiper__content"
        >
          <swiper-slide
              class="home-socials__swiper__content__slide"
              v-for="post in instaList"
              :key="post.id">
            <a :href="post.permalink" target="_blank">
              <div class="instapost-container">
                <img class="insta-post" :src="post.media_url" alt="">
                <img class="insta-icon" src="@/assets/img/icons/insta-white.svg" height="50" alt="">
              </div>
            </a>

            <div class="home-socials__swiper__content__slide__description">
              Instapost
              <span class="insta-link">
                <a href="https://www.instagram.com/beautycode.ua/" target="_blank">@beautycode</a>
              </span>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="home-socials__more">
        <div class="home-socials__more__txt">
          {{ $t('HOME.SOCIALS.BTN_TITLE') }}
        </div>
        <a href="https://www.instagram.com/beautycode.ua/" target="_blank">
          <bc-button :color-type="'white'">
            {{ $t('HOME.SOCIALS.BTN') }}
          </bc-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue.js'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.scss'

export default {
  name: "community",
  setup() {
    // const onSwiper = (swiper) => {
    const onSwiper = () => {
      // console.log('swiper');

    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get('/api/home/insta/').then(resp => {
        this.instaList = resp.data
      }).catch(err => {
        this.error = true
        console.error(err)
      })
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      instaList: [],
      error: null,
      swiperOptions: {
        breakpoints: {
          1: {
            slidesPerView: 1,
          },
          680: {
            slidesPerView: 2
          },
          850: {
            slidesPerView: 3
          },
          1100: {
            slidesPerView: 4
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.home-socials {
  overflow: hidden;

  &__swiper {
    padding-left: 20px;
    margin-bottom: 20px;

    &__content {
      overflow: visible;

      &__slide {
        &:nth-child(2n+1) {
          margin-top: 60px;
        }

        .instapost-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          transition: .3s;
          width: 350px;
          height: 350px;

          .insta-icon {
            opacity: 0;
            position: absolute;
            margin: auto;
            transition: .3s;
          }

          .insta-post {
            transition: .3s;
            object-fit: contain;
            width: 100%;
          }
        }

        .instapost-container:hover {
          > .insta-post {
            transform: scale(1.1);
            filter: blur(5px) brightness(.8);
          }

          .insta-icon {
            opacity: .8;
          }
        }

        &__description {
          margin-top: 20px;
          color: $dark-gray;

          .insta-link {
            color: $black;
          }
        }
      }
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__txt {
      margin-bottom: 1.5em;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1560px) {
  .instapost-container {
    width: 90% !important;
    height: 90% !important;
  }
}
@media screen and (max-width: 850px) {
  .home-socials__swiper__content__slide[data-v-47d5cb08]:nth-child(2n+1) {
    margin-top: 0;
  }
}
@media screen and (max-width: 680px) {
  .instapost-container {
    width: 95% !important;
    height: 95% !important;
  }
}

</style>

<template>
  <transition name="fade" mode="out-in">
    <div class="bc-menu"
         v-show="isOpen">

      <div class="bc-menu__content__title gsap-menu-item">
        {{ $t('INTERFACE.MENU') }}
      </div>

      <!--        <div class="bc-menu__content__menu-items">-->
      <div class="bc-menu__content__menu-items__item gsap-menu-item"
           :class="{'active-page' : (!!item.link.length && $route.path.includes(item.link) ) || $route.path.slice(1) === item.link }"
           v-for="(item, index) in menuItems"
           :key="index">
        <bc-router-link
            @click="$emit('menuClick')"
            :to="`/${item.link}`">
          {{ $t(item.title) }}
        </bc-router-link>
      </div>
      <!--        </div>-->

      <div class="bc-menu__content__lang gsap-menu-item">
        <div class="bc-header__logo-lang__lang" :class="{active: $i18n.locale === 'uk'}" @click="reloadPage('uk')">
          УКР
        </div>
        <div class="bc-header__logo-lang__lang" :class="{active: $i18n.locale === 'en'}" @click="reloadPage('en')">
          EN
        </div>
      </div>

      <div class="bc-menu__content__socials-order gsap-menu-item">
        <div class="bc-menu__content__socials-order__icn phone">
          <img src="@/assets/img/icons/phone.svg"
               alt="phone-icn" @click="toggleAvailablePhones">
          <div class="available-phones" v-if="showAvailablePhones" v-click-outside="toggleAvailablePhones">
            <a href="tel:+380955576600">
              095&nbsp;557&nbsp;66&nbsp;00
            </a>
            <hr>
            <a href="tel:+380975576600">
              097&nbsp;557&nbsp;66&nbsp;00
            </a>
          </div>
        </div>

        <a href="https://www.instagram.com/beautycode.ua/"
           class="bc-menu__content__socials-order__icn">
          <img src="@/assets/img/icons/insta.svg"
               alt="instagram-icn">
        </a>
        <!--        <bc-button-->
        <!--            @click="$router.push(`#${homeOrderId}`) && $emit('menuClick')" v-html="$t('INTERFACE.BOOKING')">-->
        <!--        </bc-button>-->
      </div>

    </div>
  </transition>
</template>

<script>
import {gsap} from "gsap";
import {menuItems, homeOrderId} from "@/const";
import vClickOutside from "click-outside-vue3";
import i18n from "@/i18n";
import {store} from "@/store";

let menuAnimation
let tl1 = gsap.timeline()

export default {
  name: "bc-menu",
  props: [],
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    isOpen() {
      return this.$store.getters.getIsOpenMainMenu
    }
  },
  watch: {
    isOpen(newValue, oldValue) {

      if (newValue) {
        // open menu action
        tl1.add('start')
        menuAnimation = tl1.from('.gsap-menu-item',
            {y: 50, duration: .8, ease: 'expo.out', opacity: 0, stagger: .08,}, 'start')
      }

      if (oldValue) {
        // close menu action
        menuAnimation.progress(1).kill()
      }
    }
  },
  methods: {
    toggleAvailablePhones() {
      this.showAvailablePhones = !this.showAvailablePhones
    },
    reloadPage(langIndex) {
      if (langIndex === i18n.global.locale) {
        return
      }

      if (langIndex === 'en') {
        location.href = `/en${this.$route.path}`
        store.commit('setAppLanguage', 'en')
      } else if (langIndex === 'uk') {
        location.href = this.$route.path.slice(3)
        store.commit('setAppLanguage', 'uk')
      }
    }
  },
  data() {
    return {
      menuItems,
      homeOrderId,
      showAvailablePhones: false
    }
  }
}
</script>

<style scoped lang="scss">

.active {
  opacity: 1 !important;
}

.bc-menu {
  height: 100%;
  width: 100vw;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding-top: var(--header-height);
  //padding-bottom: var(--header-height);

  &__content {
    margin-bottom: 50px;
    height: 100%;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    &__title {
      font-size: 2em;
      padding-bottom: 10px;
    }

    &__menu-items {
      &__item {
        font-size: 1.3em;
        //padding-bottom: 40px;

        &.active-page {
          border-bottom: 1px solid $black;
        }
      }
    }

    &__lang {
      margin-top: 40px;
      display: flex;
      font-size: 1.2em;

      div {
        cursor: pointer;
        opacity: .5;
        &:first-child {
          margin-right: 30px;
        }
      }
    }

    &__socials-order {
      display: flex;
      align-items: center;
      justify-content: center;

      &__icn.phone {
        position: relative;
        cursor: pointer;
        padding-right: 30px;

        hr {
          border-color: #cbcbcb;
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }

        .available-phones {
          padding: 10px 15px;
          border: 1px solid $black;
          background: $white;
          position: absolute;
          bottom: 45px;
          left: -60px;
          transition: .3s;

          a:hover {
            opacity: .5;
          }
        }
      }

    }
  }
}

@media screen and (max-height: 750px) {
  .bc-menu {
    &__content {
      //padding-bottom: 20px;

      &__title {
        //padding-bottom: 40px;
      }

      &__menu-items {
        &__item {
          //padding-bottom: 30px;
        }
      }

      &__lang {
        //margin-top: 10px;
        //margin-bottom: 20px;
      }
    }
  }
}

</style>

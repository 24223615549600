import homeOrder from "@/components/home/home-order";

const menuItems = [
    {
        title: 'HEADER.MENU.MAIN',
        link: '',
    },
    //{
    //    title: 'HEADER.MENU.SHOP',
    //    link: 'shop',
    //},
    {
        title: 'HEADER.MENU.SERVICES',
        link: 'services',
    },
    {
        title: 'HEADER.MENU.WORKS',
        link: 'works',
    },
    {
        title: 'HEADER.MENU.ABOUT',
        link: 'specialists',
    },
    {
        title: 'HEADER.MENU.DISCOUNTS',
        link: 'discounts',
    },
]

const homeOrderId = homeOrder.name

export {menuItems, homeOrderId}

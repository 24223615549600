import axios from "axios";

import router from "@/router";
import app from "../main"; // import the instance


export default function setup() {
    axios.interceptors.request.use(function (config) {
        app?.$progress.start()
        const language = app?.$i18n.locale;
        if (language) {
            config.headers.common['Accept-Language'] = language
        }
        return config

    }, function (error) {
        app?.$progress.finish()
        return Promise.reject(error)
    })

    axios.interceptors.response.use(function(response) {
        app?.$progress.finish()
        return response
    }, function (error) {
        app?.$progress.finish()
        if (error?.response?.status === 500) {
            console.error('Server Error 500')
            router.push('/500')
        }
        if (error?.response?.status === 404) {
            console.error('Client Error 404')
            router.push('/404')
        }
        return Promise.reject(error)
    })
}

<template>
  <div class="home-works">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('HOME.WORKS.TITLE') }}
      </div>
      <div class="component__subtitle">
        <div>
          {{ $t('HOME.WORKS.SUBTITLE_1') }}
        </div>
        <div>
          {{ $t('HOME.WORKS.SUBTITLE_2') }}
        </div>
      </div>

      <div class="home-works__images">
        <img class="home-works__images"
             v-for="work in works || []" :key="work.id"
             :src="work.picture" alt="">
      </div>

      <div class="home-works__more">
        <bc-router-link :to="'/works'">
          <bc-button
              :color-type="'white'">
            {{ $t('HOME.WORKS.BTN') }}
          </bc-button>
        </bc-router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "home-works",
  props: ['works'],
  created() {},
  methods: {},
  components: {},
  data() {
    return {}
  }
}

</script>

<style scoped lang="scss">
.home-works {

  .component__subtitle {
    display: flex;
    justify-content: space-between;

    > div {
      width: 45%;
    }
  }

  &__images {
    display: flex;
    justify-content: space-between;


    > img {
      width: 48%;
      height: 100%;

      &:nth-child(1) {
        margin-top: 100px;
      }

      &:nth-child(2) {
        margin-top: 200px;
      }
    }
  }

  &__more {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 960px) {
  .home-works {
    &__images {
      img:nth-child(1) {
        margin-top: 50px;
      }

      img:nth-child(2) {
        margin-top: 100px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-works {
    .component__subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > div {
        width: 100%;

        &:last-child {
          margin-top: 16px;
        }
      }
    }

    &__images {
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;

        &:nth-child(1) {
          margin-top: 50px;
        }

        &:nth-child(2) {
          margin-top: 50px;
        }
      }
    }

    &__more {
      margin-top: 50px;
    }
  }
}

</style>

<template>
  <div class="bc-header">
    <div class="component">
      <div class="bc-header__logo-lang">
        <bc-router-link
            @click="$store.commit('setIsOpenMainMenu', false)"
            :to="'/'"
            class="bc-header__logo-lang__logo">
          <!--          <img src="@/assets/img/logo.svg"-->
          <!--               alt="logo-beauty-code">-->
          <bc-logo-animated></bc-logo-animated>
        </bc-router-link>

        <div class="bc-header__logo-lang__lang ml30" :class="{active: $i18n.locale === 'uk'}" @click="reloadPage('uk')">
          УКР
        </div>
        <div class="bc-header__logo-lang__lang ml30" :class="{active: $i18n.locale === 'en'}" @click="reloadPage('en')">
          EN
        </div>
        <!--        <language-switcher-->
        <!--            v-slot="{ links }"-->
        <!--            active-class="active"-->
        <!--            class="bc-header__logo-lang__lang ml30">-->
        <!--          <router-link-->
        <!--              :to="link.url"-->
        <!--              v-for="link in links"-->
        <!--              :key="link.langIndex"-->
        <!--              :class="link.activeClass">-->
        <!--            <span-->
        <!--                @click="reloadPage(link.langIndex)">-->
        <!--              {{ link.langName }}-->
        <!--            </span>-->
        <!--          </router-link>-->
        <!--        </language-switcher>-->
      </div>

      <div class="bc-header__menu">
        <div class="bc-header__menu__item"
             :class="{'active-page' : (!!item.link.length && $route.path.includes(item.link) ) || $route.path.slice(1) === item.link  }"
             v-for="(item, index) in menuItems"
             :key="index">
          <bc-router-link :to="`/${item.link}`">
            {{ $t(item.title) }}
          </bc-router-link>
        </div>
      </div>

      <div class="bc-header__btns">
        <div class="bc-header__btns__icn phone" @click="$store.commit('setIsOpenMainMenu', false)">

          <img src="@/assets/img/icons/phone.svg"
               alt="phone-icn" @click="toggleAvailablePhones">
          <div class="available-phones" v-if="showAvailablePhones" v-click-outside="toggleAvailablePhones">
            <a href="tel:+380955576600">
              095&nbsp;557&nbsp;66&nbsp;00
            </a>
            <hr>
            <a href="tel:+380975576600">
              097&nbsp;557&nbsp;66&nbsp;00
            </a>
          </div>
        </div>
        <a href="https://www.instagram.com/beautycode.ua/"
           @click="$store.commit('setIsOpenMainMenu', false)"
           class="bc-header__btns__icn">
          <img src="@/assets/img/icons/insta.svg"
               alt="instagram-icn">
        </a>

        <bc-router-link :to="'/cart'"
                        @click="$store.commit('setIsOpenMainMenu', false)"
                        class="bc-header__btns__cart"
                        :class="$route.path.includes('shop') || $route.path.includes('cart') || cartItemsCount ? 'show-cart': 'hide-cart'">
          <bc-button
              class="bc-header__btns__cart__btn"
              :color-type="'white'">
            <img
                src="@/assets/img/icons/shopping-cart.svg"
                alt="bc-cart"
                class="bc-header__btns__cart__btn__icon" :class="{'empty-cart' : !cartItemsCount}">
            <div class="bc-header__btns__cart__btn__txt" :class="{'empty-cart' : !cartItemsCount}">
                  <span class="bc-header__btns__cart__btn__txt__lg">
                    {{ $t('INTERFACE.CART') }}&nbsp;<span v-if="cartItemsCount">({{ cartItemsCount }})</span>
                  </span>
              <span class="bc-header__btns__cart__btn__txt__sm">
                    <span v-if="cartItemsCount">{{ cartItemsCount }}</span>
                    <span v-else>&nbsp;</span>
                  </span>
            </div>
          </bc-button>
        </bc-router-link>


        <bc-button class="bc-header__btns__order"
                   @click="$router.push(`#${homeOrderId}`)"
                   v-html="$t('INTERFACE.BOOKING')">
        </bc-button>


        <div class="bc-header__btns__menu"
             @click="toggleMenu">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="top-line" :class="{open:isOpenMenu}" d="M0 6H22" stroke="black" stroke-width="2"/>
            <path class="bottom-line" :class="{open:isOpenMenu}" :d="isOpenMenu ? 'M6 16L28 16' : 'M6 16L22 16'"
                  stroke="black" stroke-width="2"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HomeOrder from "@/components/home/home-order";
import vClickOutside from 'click-outside-vue3'
import bcLogoAnimated from "@/components/UI/interface/bc-logo-animated";
import {menuItems} from "@/const";
import i18n from "@/i18n";
import {store} from "@/store";

export default {
  name: "bc-header",
  components: {bcLogoAnimated},
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    cartItemsCount() {
      return this.$store.getters.cartItemsCount
    },
    isOpenMenu() {
      return this.$store.getters.getIsOpenMainMenu
    }
  },
  methods: {
    toggleMenu() {
      let isOpenMainMenu = this.$store.getters.getIsOpenMainMenu
      this.$store.commit('setIsOpenMainMenu', !isOpenMainMenu)
    },
    reloadPage(langIndex) {
      if (langIndex === i18n.global.locale) {
        return
      }

      if (langIndex === 'en') {
        store.commit('setAppLanguage', 'en')
        location.href = `/en${this.$route.path}`
      } else if (langIndex === 'uk') {
        store.commit('setAppLanguage', 'uk')
        if (this.$route.path.slice(3)) {
          location.href = this.$route.path.slice(3)
        } else {
          location.href = '/'
        }
      }
    },
    toggleAvailablePhones() {
      this.showAvailablePhones = !this.showAvailablePhones
    },
  },
  data() {
    return {
      homeOrderId: HomeOrder.name,
      menuItems,
      showAvailablePhones: false
    }
  }
}
</script>

<style scoped lang="scss">

.bc-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: $white-backdrop;
  backdrop-filter: blur(10px);
  height: var(--header-height);
  z-index: 3000;
  font-size: .87em;

  .component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__logo-lang {
    display: flex;
    align-items: center;

    &__logo {
      position: relative;
      z-index: 1100;
    }

    &__lang {
      opacity: .5;

      &:not(:first-of-type) {
        margin-left: 15px;
      }
    }


    > div {
      cursor: pointer;
    }

    img {
      width: 120px;
    }

  }

  &__menu {
    display: flex;
    justify-content: center;

    &__item {
      margin: 0 17px;
      cursor: pointer;
      transition: .2s;

      &.active-page {
        border-bottom: 1px solid $black;
      }

      &:hover {
        opacity: .5;
      }
    }
  }

  &__btns {

    display: flex;
    align-items: center;

    > button:not(:last-of-type) {
      margin-right: 20px;
    }

    &__icn {
      margin-right: 20px;
      transition: .2s;
      display: flex;
      align-items: center;
      img:hover {
        opacity: .5;
      }
      &.phone {
        cursor: pointer;
        position: relative;
        hr {
          border-color: #cbcbcb;
          border-top: 0;
          border-right: 0;
          border-left: 0;
        }
        .available-phones {
          opacity: 1;
          padding: 10px 15px;
          border: 1px solid $black;
          background: $white;
          position: absolute;
          top: 45px;
          left: -60px;
          transition: .3s;

          a:hover {
            opacity: .5;
          }
        }
      }


    }


    &__cart {
      transition: .5s ease;

      &.hide-cart {
        max-width: 0;
        overflow: hidden;
        opacity: 0;
      }

      &.show-cart {
        max-width: 200px;
        overflow: initial;
        opacity: 1;
      }

      &__btn {
        position: relative;
        margin-right: 20px;
        padding-right: 20px !important;
        padding-left: 20px !important;


        &__icon {
          position: absolute;
          left: 12px;
        }

        &__txt {
          padding-left: 26px;

          &__sm {
            display: none;
          }
        }
      }
    }

    &__order {
      position: relative;
      z-index: 100;
    }

    &__menu {
      position: relative;
      z-index: 1100;
      display: none;
      cursor: pointer;

      svg {
        overflow: visible;

        .top-line {
          transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

          &.open {
            transform: rotate(45deg) translate(5px, -5px);
          }
        }

        .bottom-line {
          transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

          &.open {
            transform: rotate(-45deg) translate(-17.5px, 0);
          }
        }
      }
    }
  }
}

.active {
  opacity: 1 !important;
}

@media screen and (max-width: 1400px) {
  .bc-header {
    &__menu {
      &__item {
        margin: 0 10px;
      }
    }
  }

}

@media screen and (max-width: 1270px) {
  .bc-header {
    &__logo-lang {
      &__lang {
        display: none;
      }
    }

    &__menu {
      display: none;
    }

    &__btns {
      > button {
        margin-right: 20px;
      }

      &__menu {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .bc-header {
    &__btns {
      &__cart {
        &__btn {
          &__icon {
            &.empty-cart {
              left: 7px;
            }
          }

          &__txt {
            &.empty-cart {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .bc-header__btns__icn {
    display: none;
  }
  .bc-header__btns__cart__btn__txt__lg {
    display: none;
  }
  .bc-header__btns__cart__btn__txt__sm {
    display: block;
  }

}

@media screen and (max-width: 600px) {
  .bc-header {
    &__logo-lang {
      &__logo {
        img {
          width: 85px;
        }
      }
    }

    &__btns {
      > button {
        margin-right: 10px;
      }

      &__cart__btn {
        margin-right: 5px;
      }
    }

  }
}
</style>

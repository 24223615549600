import { useHead } from '@vueuse/head';
import { unref, computed } from 'vue';

import i18n from "@/i18n";
import {store} from "@/store";

export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function setGlobalLocale(locale) {
    i18n.global.locale = locale
    store.commit('setAppLanguage', locale)
}

export function getLocaleLink(link) {
    if (i18n.global.locale === 'en') {
        return `/en${link}`
    }
    return link
}

let siteTitle = 'Beauty Code';
let separator = '|';

export const usePageTitle = (pageTitle, pageDescription) => {
    if (unref(pageTitle) && unref(pageDescription)) {
        useHead(computed(() => ({
            title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
            meta: [
                {
                    name: `description`,
                    content: `${unref(pageDescription)} | Beauty Code`,
                },
            ],
        })));
    }
}

// this is expected to only be called once - should maybe add a check / error
export const usePageTitleTemplate = (newSiteTitle, newSeparator, newSiteDescription) => {
    siteTitle = newSiteTitle;
    if (newSeparator) separator = newSeparator;
    return useHead({
        title: siteTitle,
        meta: [
            {
                name: `description`,
                content: computed(() => `Beauty Code | ${newSiteDescription}`),
            },
        ],
    });
};

<template>
  <div class="bc-card" :class="`bc-card__${cardType}`">
    <img class="bc-card__img" :src="img" :alt="`${cardType}-img`">
    <div class="bc-card__description">
      <div class="bc-card__description__title" v-html="title"></div>
      <div class="bc-card__description__subtitle" v-html="subtitle"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bc-card",
  props: {
    cardType: {
      // ['specialist' | 'discount']
      type: String,
      require: true
    },
    img: {
      type: String,
      require: true
    },
    title: {
      type: String,
      require: true
    },
    subtitle: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.bc-card {
  display: flex;
  flex-direction: column;
  //max-width: 487px;

  &__description {
    &__title {
      margin-top: 1em;
      font-size: 1.33em;
      width: fit-content;
    }

    &__subtitle {
      margin-top: .42em;
      color: $dark-gray;
      width: fit-content;
    }
  }


  &__specialist {
    .bc-card__img {
    }
  }

  &__discount {
    .bc-card__img {
    }
  }
}
@media screen and (max-width: 900px){
  .bc-card {
    &__specialist,
    &__discount {
      .bc-card__description__title {
        font-size: 1.2em;
      }
    }

  }
}

@media screen and (max-width: 600px){
  .bc-card {
    &__specialist,
    &__discount {
      .bc-card__description__title,
      .bc-card__description__subtitle {
        text-align: center;
        width: inherit;
      }
      .bc-card__description__title {
        font-size: 1.2em;
      }
    }

  }
}

</style>
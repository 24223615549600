import bcButton from "@/components/UI/interface/bc-button";
import bcCard from "@/components/UI/cards/bc-card";
import bcCountControl from "@/components/UI/shop/bc-count-control";
import bcAccordionBig from "@/components/UI/interface/bc-accordion-big";
import bcProgressCircular from "@/components/UI/interface/bc-progress-circular";
import bcThanksForOrder from "@/components/UI/dialogs/bc-thanks-for-order";
import bcAccordion from "@/components/UI/interface/bc-accordion";
import bcRouterLink from "@/components/UI/navigation/bc-router-link";

export default [
    bcButton,
    bcAccordion,
    bcCard,
    bcCountControl,
    bcProgressCircular,
    bcThanksForOrder,
    bcAccordionBig,
    bcRouterLink
]

<template>
  <div class="bc-logo-animated">
    <svg class="bc-logo-animated__svg" width="150" viewBox="0 0 136 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class=" big-right-to--header" d="M1.09856 0H0V66.89H1.09856V0Z" fill="#161616"/>
      <path class="" d="M3.8427 0H2.74414V66.89H3.8427V0Z" fill="#161616"/>
      <path class=" big-left-to--header" d="M10.8952 0H8.59668V66.89H10.8952V0Z" fill="#161616"/>
      <path class=" left-to" d="M19.3854 0H17.0869V59.87H19.3854V0Z" fill="#161616"/>
      <path class="" d="M33.8415 0H31.543V59.87H33.8415V0Z" fill="#161616"/>
      <path class="" d="M41.1534 0H35.7451V59.87H41.1534V0Z" fill="#161616"/>
      <path class="" d="M106 0H102V60H106V0Z" fill="#161616"/>
      <path class=" right-to1--header" d="M116 0H112V60H116V0Z" fill="#161616"/>
      <path class=" big-left-to--header" d="M130.233 0H126.024V66.89H130.233V0Z" fill="#161616"/>
      <path class=" left-to1--header" d="M53.9587 0H51.6602V59.87H53.9587V0Z" fill="#161616"/>
      <path class=" right-to--header" d="M57.9811 0H55.6826V59.87H57.9811V0Z" fill="#161616"/>
      <path class="" d="M13.9032 0H12.8047V66.89H13.9032V0Z" fill="#161616"/>
      <path class="" d="M23.7792 0H22.6807V59.87H23.7792V0Z" fill="#161616"/>
      <path class=" left-to--header" d="M28.1679 0H27.0693V59.87H28.1679V0Z" fill="#161616"/>
      <path class=" right-to--header" d="M43.8974 0H42.7988V59.87H43.8974V0Z" fill="#161616"/>
      <path class=" right-to1--header" d="M46.8271 0H45.7285V59.87H46.8271V0Z" fill="#161616"/>
      <path class="" d="M64 0H63V60H64V0Z" fill="#161616"/>
      <path class="" d="M66.7197 0H65.6211V66.89H66.7197V0Z" fill="#161616"/>
      <path class="" d="M69.5976 0H68.499V66.89H69.5976V0Z" fill="#161616"/>
      <path class=" right-to--header" d="M72 0H71V60H72V0Z" fill="#161616"/>
      <path class="" d="M75 0H74V60H75V0Z" fill="#161616"/>
      <path class=" left-to--header" d="M82 0H81V60H82V0Z" fill="#161616"/>
      <path class="" d="M88 0H87V60H88V0Z" fill="#161616"/>
      <path class=" left-to1--header" d="M93 0H92V60H93V0Z" fill="#161616"/>
      <path class="" d="M97 0H96V60H97V0Z" fill="#161616"/>
      <path class=" right-to1--header" d="M108 0H107V60H108V0Z" fill="#161616"/>
      <path class="" d="M120 0H119V60H120V0Z" fill="#161616"/>
      <path class=" big-right-to--header" d="M123 0H122V67H123V0Z" fill="#161616"/>
      <path class="" d="M132.61 0H131.512V66.89H132.61V0Z" fill="#161616"/>
      <path class=" big-left-to--header" d="M135.353 0H134.255V66.89H135.353V0Z" fill="#161616"/>
      <path class="" d="M16.7881 66.9041V61.4241H18.7824C19.3965 61.4241 19.8641 61.5368 20.1908 61.7624C20.5176 61.9879 20.6809 62.3036 20.6809 62.7095C20.6809 63.0083 20.5795 63.262 20.3823 63.4876C20.1795 63.7074 19.926 63.8653 19.6162 63.9499V63.9668C20.0049 64.0119 20.3204 64.1585 20.5514 64.4009C20.7823 64.6377 20.9006 64.9309 20.9006 65.2748C20.9006 65.7766 20.7204 66.1768 20.3598 66.47C19.9993 66.7632 19.5091 66.9154 18.8894 66.9154H16.7881V66.9041ZM18.0219 62.3318V63.6285H18.5627C18.8162 63.6285 19.019 63.5665 19.1655 63.4425C19.312 63.3184 19.3852 63.1493 19.3852 62.935C19.3852 62.5348 19.0866 62.3318 18.4838 62.3318H18.0219ZM18.0219 64.5475V65.9908H18.6923C18.9739 65.9908 19.1993 65.9231 19.3627 65.7935C19.526 65.6638 19.6049 65.4834 19.6049 65.2522C19.6049 65.0323 19.526 64.8576 19.3683 64.7335C19.2105 64.6095 18.9852 64.5475 18.7035 64.5475H18.0219Z" fill="#161616"/>
      <path class="" d="M28.5959 66.9042H25.3115V61.4241H28.472V62.4333H26.5453V63.6511H28.3368V64.6546H26.5453V65.9062H28.5959V66.9042Z" fill="#161616"/>
      <path class="" d="M37.8067 66.9042H36.4603L36.0716 65.6864H34.1223L33.7336 66.9042H32.3984L34.3927 61.4241H35.8575L37.8067 66.9042ZM35.7899 64.7335L35.204 62.8899C35.1589 62.7546 35.1307 62.5855 35.1138 62.3938H35.0857C35.0744 62.5573 35.0406 62.7152 34.9899 62.873L34.3927 64.7279H35.7899V64.7335Z" fill="#161616"/>
      <path class="" d="M46.466 64.5306C46.466 66.1769 45.6998 67 44.1674 67C42.6802 67 41.9365 66.1938 41.9365 64.587V61.4185H43.1759V64.5982C43.1759 65.4834 43.5252 65.9288 44.2125 65.9288C44.8942 65.9288 45.2322 65.5003 45.2322 64.6434V61.4185H46.466V64.5306Z" fill="#161616"/>
      <path class="" d="M55.0179 62.4276H53.4574V66.9042H52.218V62.4276H50.6631V61.4185H55.0179V62.4276Z" fill="#161616"/>
      <path class="" d="M63.4003 61.4185L61.6144 64.9534V66.9042H60.3807V64.976L58.6455 61.4185H60.0539L60.9384 63.465C60.9553 63.5045 60.9947 63.6511 61.0623 63.9104H61.0792C61.113 63.73 61.1525 63.5834 61.1976 63.4763L62.0933 61.4185H63.4003Z" fill="#161616"/>
      <path class="" d="M84.5496 66.7067C84.1496 66.8984 83.6257 66.9999 82.9834 66.9999C82.144 66.9999 81.4849 66.7518 81.0004 66.2557C80.5215 65.7595 80.2793 65.1055 80.2793 64.2768C80.2793 63.4029 80.5497 62.6925 81.0905 62.1456C81.6314 61.5987 82.3356 61.3281 83.1975 61.3281C83.7327 61.3281 84.1834 61.3958 84.5496 61.5311V62.7207C84.1834 62.5008 83.7665 62.3937 83.2989 62.3937C82.7863 62.3937 82.3694 62.5572 82.0539 62.8786C81.7384 63.1999 81.5807 63.6397 81.5807 64.1922C81.5807 64.7222 81.7271 65.145 82.0257 65.4607C82.3243 65.7764 82.7243 65.9343 83.2313 65.9343C83.7102 65.9343 84.1552 65.8159 84.5552 65.5848V66.7067H84.5496Z" fill="#161616"/>
      <path class="" d="M91.3329 66.9999C90.5498 66.9999 89.9076 66.7462 89.4175 66.2331C88.9217 65.7257 88.6738 65.0548 88.6738 64.2373C88.6738 63.3691 88.9273 62.6699 89.4287 62.1343C89.9301 61.5987 90.5949 61.3281 91.423 61.3281C92.2061 61.3281 92.8371 61.5875 93.3216 62.0949C93.8061 62.6079 94.0483 63.2845 94.0483 64.1245C94.0483 64.9871 93.7948 65.6806 93.299 66.2049C92.792 66.7349 92.1385 66.9999 91.3329 66.9999ZM91.3892 62.3937C90.9555 62.3937 90.6118 62.5572 90.3583 62.8842C90.1048 63.2055 89.9752 63.6397 89.9752 64.1753C89.9752 64.7165 90.1048 65.145 90.3583 65.4607C90.6118 65.7764 90.9442 65.9343 91.3611 65.9343C91.7836 65.9343 92.1216 65.7821 92.3751 65.472C92.623 65.1619 92.7469 64.7391 92.7469 64.1922C92.7469 63.6228 92.6286 63.183 92.3864 62.8673C92.1385 62.5515 91.8061 62.3937 91.3892 62.3937Z" fill="#161616"/>
      <path class="" d="M98.4648 66.9042V61.4241H100.408C102.352 61.4241 103.327 62.3149 103.327 64.0965C103.327 64.9534 103.062 65.63 102.532 66.143C102.003 66.6504 101.293 66.9098 100.408 66.9098H98.4648V66.9042ZM99.693 62.4276V65.9006H100.301C100.837 65.9006 101.259 65.7427 101.563 65.4214C101.868 65.1 102.02 64.6603 102.02 64.1134C102.02 63.5891 101.868 63.1775 101.569 62.8787C101.27 62.5799 100.842 62.4276 100.301 62.4276H99.693Z" fill="#161616"/>
      <path class="" d="M111.028 66.9042H107.743V61.4241H110.904V62.4333H108.977V63.6511H110.768V64.6546H108.977V65.9062H111.028V66.9042Z" fill="#161616"/>
    </svg>
  </div>
</template>

<script>
import {gsap, Power1} from "gsap";

export default {
  name: "bc-logo-animated",
  mounted() {
    let tl2 = gsap.timeline({defaults:{ease: Power1.easeInOut, delay: 2}})
    tl2
        .add('start')
        .to('.left-to--header',
            {x: 20, duration: 4, repeat: -1, yoyo: true, stagger: 0.1, repeatDelay: .0}, 'start')
        .to('.left-to1--header',
            {x: 20, duration: 6, repeat: -1, yoyo: true, stagger: 0.2, repeatDelay: .1}, 'start')
        .to('.right-to--header',
            {x: -20, duration: 6, repeat: -1, yoyo: true, stagger: 0.3, repeatDelay: .2}, 'start')
        .to('.right-to1--header',
            {x: -20, duration: 5, repeat: -1, yoyo: true, stagger: 0.2, repeatDelay: .3}, 'start')
        .to('.big-right-to--header',
            {x: 10, duration: 4, repeat: -1, yoyo: true, stagger: 0.1, repeatDelay: .5}, 'start')
        .to('.big-left-to--header',
            {x: -10, duration: 5, repeat: -1, yoyo: true, stagger: 0.1, repeatDelay: .5}, 'start')
  }
}
</script>

<style scoped lang="scss">
.bc-logo-animated {
  display: flex;
  align-items: center;

  &__svg {
    width: 130px;
    object-fit: contain;
  }
}
@media screen and (max-width: 600px) {
  .bc-logo-animated {
    &__svg {
      width: 95px;
    }
  }
}
@media screen and (max-width: 360px) {
  .bc-logo-animated {
    &__svg {
      width: 75px;
    }
  }
}
</style>
<template>
  <div class="app" v-cloak>
    <transition name="fade" mode="out-in">
      <bc-loading-fullscreen v-if="loading"/>
    </transition>

    <div>
      <vue3-progress/>
      <header>
        <bc-header/>
        <bc-menu @wheel.prevent
                 @touchmove.prevent
                 @scroll.prevent
                 @menu-click="menuClickClose"
                 @change-lang="reloadPage"/>
      </header>

      <router-view v-slot="{ Component }" class="hide-glitch">
        <transition name="fade" mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>

      <home-order/>
      <footer>
        <bc-footer/>
      </footer>
      <bc-cookies/>
    </div>
  </div>
</template>

<script>
import bcHeader from "@/components/UI/navigation/bc-header";
import bcFooter from "@/components/UI/navigation/bc-footer";
import bcLoadingFullscreen from "@/components/UI/navigation/bc-loading-fullscreen";
import homeOrder from "@/components/home/home-order";
import bcMenu from "@/components/UI/navigation/bc-menu";
import bcCookies from "@/components/UI/navigation/bc-cookies";

import i18n from "@/i18n";
import {store} from "@/store";
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: 'App',
  setup() {
    // const {t} = i18n.global
    const siteData = reactive({
      title: `Beauty Code`,
      // description: t('META.DESCRIPTION')
      description: 'Ми знаємо твій код краси',
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    })
    // usePageTitleTemplate('Beauty Code', '|', t('META.DESCRIPTION'));

    document.querySelector('html').setAttribute('lang', i18n.global.locale)
  },
  components: {
    bcHeader,
    bcFooter,
    bcLoadingFullscreen,
    homeOrder,
    bcMenu,
    bcCookies
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    this.$store.commit('updateCartFromLocalStorage')
    setTimeout(() => {
      this.minimal_animation_timing = true
    }, 2200)
  },
  watch: {
    minimal_animation_timing() {
      if (document.readyState === "complete") {
        this.loading = false
      } else {
        document.onreadystatechange = () => {
          if (document.readyState === "complete") {
            this.loading = false
          }
        }
      }
    }
  },
  created() {
    this.seoFetch()
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        this.$router.go(0)
      }, 10)
    },
    menuClickClose() {
      setTimeout(() => {
        this.$store.commit('setIsOpenMainMenu', false)
      }, 300)
    },
    seoFetch() {
      this.$http.get('/api/seo/').then(response => {
        store.commit('setSeoData', response.data)
      })
    }
  },
  data() {
    return {
      loading: true,
      minimal_animation_timing: false,
    }
  }
}
</script>

<style lang="scss">
.app {
  height: 100vh;
  background: $white;
  margin: 0 auto;
}

@font-face {
  font-family: NAMU;
  src: url("assets/fonts/NAMU-Pro.woff2") format("woff2"),
  url("assets/fonts/NAMU-Pro.woff") format("woff"),
  url("assets/fonts/NAMU-Pro.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.hide-glitch {
  min-height: 80vh;
}
</style>

import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue'
import VClickOutside from "click-outside-vue3";
import Vue3Progress from "vue3-progress";
import axios from "axios";
import { createHead } from '@vueuse/head'

import i18n from "@/i18n"
import router from "@/router"
import { store } from "@/store";

import './assets/styles/styles.scss'
import UIComponents from './components/UI/index'
import interceptorsSetup from '@/interceptors/index'

const app = createApp(App)

UIComponents.forEach(component => {
    app.component(component.name, component)
})

const head = createHead()
app.use(head)
app.use(router)
app.use(i18n)
app.use(store)
app.use(VClickOutside)

const options = {
    position: "fixed",
    height: "5px",
    color: "#000000",
};
app.use(Vue3Progress, options)

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
app.config.globalProperties.$http = axios;

interceptorsSetup()

export default app
    .mount('#app')

<template>
  <div class="bc-home">
    <home-main :data="mainData.main"/>
    <home-works :works="mainData.works" v-if="mainData.works"/>
    <home-services :services="mainData.services" v-if="mainData.services"/>
    <home-specialists :specialists="mainData.specialists" v-if="mainData.specialists"/>
    <home-discounts :discounts="mainData.discounts" v-if="mainData.discounts"/>
    <home-socials/>
  </div>
</template>

<script>
import {computed} from "vue";
// import {usePageTitle} from "@/utils";
import {store} from "@/store";

import homeMain from "@/components/home/home-main";
import homeWorks from "@/components/home/home-works";
import homeServices from "@/components/home/home-services";
import homeSpecialists from "@/components/home/home-specialists";
import homeDiscounts from "@/components/home/home-discounts";
import homeSocials from "@/components/home/home-socials";
import {useHead} from "@vueuse/head";

export default {

  name: 'bc-home',
  components: {
    homeMain,
    homeWorks,
    homeServices,
    homeSpecialists,
    homeDiscounts,
    homeSocials,
  },
  created() {
    useHead({
      // Can be static or computed
      title: computed(() => store.getters.getSeoDataForPage('MAIN')?.title),
      meta: [
        {
          name: `description`,
          content: computed(() => store.getters.getSeoDataForPage('MAIN')?.description),
        },
      ],
    })
    // usePageTitle(
    //     computed(() => store.getters.getSeoDataForPage('MAIN')?.title),
    //     computed(() => store.getters.getSeoDataForPage('MAIN')?.description))
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$http.get('/api/main/').then(resp => {
        this.mainData = resp.data
      }).catch(err => {
        console.log('error occurred')
        this.error = true
        console.error(err)
      })
    },
  },
  mounted() {},
  data() {
    return {
      fetched: 0,
      fetchingCount: 5,
      homeData: {},
      error: false,
      mainData: {},
    }
  }
}
</script>

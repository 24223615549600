import { createI18n } from 'vue-i18n/index'
import uk from './locales/uk.json'
import en from './locales/en.json'
import {store} from "@/store";

const i18n = createI18n({
    locale: store.getters.getAppLanguage || 'uk', // set locale
    fallbackLocale: ['uk', 'en'], // set fallback locale
    messages: {
        uk,
        en
    },
    warnHtmlInMessage: 'off' // disable of the Detected HTML in message
})

export default i18n

<template>
  <div class="home-discounts">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('HOME.DISCOUNTS.TITLE') }}
      </div>
      <div class="component__subtitle">
        {{ $t('HOME.DISCOUNTS.SUBTITLE') }}
      </div>
      <div class="home-discounts__content">
        <bc-card
            class="home-discounts__content__card"
            v-for="discount in discounts || []"
            :key="discount.id"
            :card-type="'discount'"
            :img="discount.picture"
            :title="discount.title"
        >
        </bc-card>
      </div>
      <div class="home-discounts__more">
        <div class="home-discounts__more__txt">
          {{ $t('HOME.DISCOUNTS.BTN_TITLE') }}
        </div>
        <bc-router-link :to="'/discounts'">
          <bc-button
              :color-type="'white'">
            {{ $t('HOME.DISCOUNTS.BTN') }}
          </bc-button>
        </bc-router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-discounts",
  props: ['discounts'],
  components: {},
  methods: {},
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.home-discounts {
  background: $black;
  color: $white;

  .component {
    display: flex;
    flex-direction: column;

    &__subtitle {
      text-align: center;
      max-width: 650px;
      align-self: center;
    }
  }

  &__content {
    margin: 4em 0;
    display: flex;
    justify-content: space-between;

    &__card {
      max-width: 30%;
      padding: 0 15px;

      &:nth-child(2) {
        margin-top: 3em;
      }
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__txt {
      margin-bottom: 1.5em;
    }
  }
}

@media screen and (max-width: 900px) {
  .home-discounts {
    &__content {
      flex-wrap: wrap;
      justify-content: space-around;

      &__card {
        max-width: 45%;
        width: 45%;

        &:nth-child(2) {
          margin-top: 0;
        }

        &:last-child {
          margin-top: 4em;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-discounts {
    &__content {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 0;

      &__card {
        max-width: 100%;
        width: 100%;

        &:nth-child(2n+1) {
          margin-top: 40px;
        }

        &:nth-child(n) {
          margin-top: 40px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="bc-count-control">
    <div class="decrease" @click="$emit('decreaseCount')">
      <svg  width="22" height="22" viewBox="0 0 22 22" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11H22" stroke="black"/>
      </svg>
    </div>

    <div class="bc-count-control__count">{{ countModel }}</div>

    <div class="increase" @click="$emit('increaseCount')">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M0 11H22" stroke="#161616"/>
        <path d="M11 0L11 22" stroke="#161616"/>
      </svg>
    </div>

  </div>
</template>

<script>
export default {
  name: "bc-count-control",
  props: {
    countModel: {
      type: Number,
      required: true,
      default() {
        return 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bc-count-control {
  border: 1px $black solid;
  padding: .5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  .decrease, .increase {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      transition: .05s;
    }
    svg:active {
      transform: scale(0.8);
      opacity: .5;
    }
  }

  &__count {
    width: 70px;
    text-align: center;
    padding: 0 1em;
  }
}
@media screen and (max-width: 600px) {
  .bc-count-control {
    padding: .2em .5em;
    .decrease, .increase {
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &__count {
      width: 50px;
      text-align: center;
      padding: 0 1em;
    }
  }
}
</style>
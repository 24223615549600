<template>
  <div class="bc-accordion">
    <div class="bc-accordion__title"
         @click="$emit('setActiveAccordion')">
      {{ title }}
      <img class="bc-accordion__title__toggle"
           :class="{active: isActive, invert: isWhiteScheme}"
           src="@/assets/img/icons/more.svg" alt="toggle">
    </div>
    <div class="bc-accordion__content"
         :class="{active: isActive}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "bc-accordion",
  props: {
    title: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default() {
        return false
      }
    },
    isWhiteScheme: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bc-accordion {
  overflow: hidden;

  &__title {
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    cursor: pointer;

    &__toggle {
      height: 22px;
      width: 22px;
      transition: .3s;
      margin-right: 15px;
      margin-left: 10px;

      &.active {
        transform: rotate(135deg);
      }

      &.invert {
        filter: invert(1);
      }
    }
  }

  &__content {
    transition: .5s;
    max-height: 0;
    opacity: 0;

    &.active {
      max-height: 5000px; // can't be that long
      opacity: 1;
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .bc-accordion {
    &__title {
      padding: 0 15px;
      &__toggle {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="home-specialists">
    <div class="component component__padding">
      <div class="component__title">
        {{ $t('HOME.SPECIALISTS.TITLE') }}
      </div>
      <div class="component__subtitle">
        {{ $t('HOME.SPECIALISTS.SUBTITLE') }}
      </div>
      <div class="home-specialists__content">
        <bc-card
            class="home-specialists__content__card"
            v-for="specialist in specialists || []"
            :key="specialist.id"
            :card-type="'specialist'"
            :img="specialist.picture"
            :title="specialist.name"
            :subtitle="specialist.position">
        </bc-card>
      </div>
      <div class="home-specialists__btn">
        <bc-router-link :to="'/specialists'">
          <bc-button
              :color-type="'white'">
            {{ $t('HOME.SPECIALISTS.BTN') }}
          </bc-button>
        </bc-router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "home-specialists",
  props: ['specialists'],
  components: {},
  methods: {},
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">

.home-specialists {
  //height: 100vh;
  .component {
    display: flex;
    flex-direction: column;

    &__subtitle {
      text-align: center;
      max-width: 765px;
      align-self: center;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 4em 0;

    &__card {
      max-width: 30%;
      padding: 0 15px;

      &:nth-child(2n+1) {
        margin-top: 4em;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .home-specialists {
    &__content {
      flex-wrap: wrap;
      justify-content: space-around;

      &__card {
        max-width: 45%;
        width: 45%;

        &:nth-child(2n+1) {
          margin-top: 0;
        }

        &:last-child {
          margin-top: 4em;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home-specialists {
    &__content {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 0;

      &__card {
        max-width: 100%;
        width: 100%;

        &:nth-child(2n+1) {
          margin-top: 40px;
        }

        &:nth-child(n) {
          margin-top: 40px;
        }
      }
    }
  }
}

</style>
